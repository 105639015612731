.hub {
  .markdown {
    max-width: 100%;
    min-width: 0;

    h1 {
      font-size: 28px;
    }

    h2 {
      font-size: 24px;
    }

    h3 {
      font-size: 22px;
      margin-bottom: 10px;
      margin-top: 25px;
    }

    h4,
    h5,
    h6 {
      font-size: 18px;
    }

    blockquote {
      background-color: #f9f9f9;
      border-left: 5px solid #ccc;
      padding: 0.5em 10px;

      p {
        margin-bottom: 0;
        font-size: 14px;
      }

      margin-bottom: 10px;
    }

    code {
      background-color: #eee;
      color: black;
      padding: 2px 4px;
    }

    pre {
      font-family: "SF Mono", Monaco, Consolas, monospace;
      font-size: 14px;
      background-color: #333;
      color: white;
      padding: 10px;
      margin: 5px 0 15px;

      code {
        background-color: transparent;
        color: white;

        a {
          color: #eee;
        }
      }

      overflow-x: auto;
    }
  }
}
