.chat-message-user,
.chat-message-assistant {
  width: 100%;
}

.chat-message-assistant {
  background-color: #f6f6f6;
}

.chat-message-user {
  background-color: #fff;
}

.chat-message-user-inner,
.chat-message-assistant-inner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 15px auto;
  max-width: var(--chakra-sizes-container-lg);
  padding: 0 25px;
  width: 100%;
  white-space: pre-wrap;
  font-size: 16px;
  color: black;
  line-height: 1.8;
  position: relative;
}

.chat-message-wrapper {
  display: flex;
}

.chat-avatar-image {
  width: 120px;
  height: 120px;
  border-radius: 50%;
}

.chat-wrapper-text {
  .chat-toggle-subtitles,
  .chat-avatar-name,
  .chat-message-feedback-voice {
    display: none;
  }

  .chat-avatar-image {
    width: 48px;
    height: 48px;
  }
}

.chat-toggle-subtitles {
  &.is-off:after {
    content: "";
    position: absolute;
    width: 1px;
    background-color: black;
    height: 27px;
    top: 3px;
    transform: rotate(-45deg);
  }
}

.chat-wrapper-voice {
  .chat-message-wrapper:not(.is-last-turn) {
    display: none;
  }

  .chat-retry-message {
    display: none;
  }

  .chat-loading-indicator {
    position: absolute;
    right: 0;
    top: 0;
    text-align: right;
  }

  .chat-message-wrapper.is-last-turn {
    .chat-message-user {
      display: none;
    }

    .chat-message-user-content {
      display: none;
    }

    .feedback-message-inline {
      display: flex;
      justify-content: center;

      button {
        position: inherit;
      }
    }

    .feedback-message-inline-text {
      border: 1px solid var(--chakra-colors-brand-gray-400);
      max-width: 500px;
      padding: 15px;
      border-radius: 5px;
      font-style: italic;
    }

    .chat-message-assistant {
      background-color: transparent;
      margin-top: 2vh;
    }

    .chat-message-assistant-content {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 0;
    }

    .chat-message-assistant-avatar {
      width: 120px;
      height: 120px;
    }

    .chat-message-assistant-text {
      display: none;
      margin-bottom: 0;
      max-width: 600px;
      font-size: 14px;
      order: 2;
      padding-right: 0;
      //text-align: center;
      // margin-bottom: 250px;
    }
  }

  // .chat-message-feedback-voice {
  //   margin-bottom: 250px;
  // }

  &.show-subtitles {
    .chat-message-assistant-text {
      display: flex !important;
    }
  }
}

.chat-layout-ios {
  height: 100%;
  overflow: hidden;
}

@media (min-width: 30em) {
  .chat-wrapper-voice {
    .chat-message-wrapper.is-last-turn {
      .chat-message-assistant {
        margin-top: 10vh;
      }
    }
  }
}

.avatar-pulse-effect {
  background: black;
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgb(95, 129, 168);
  transform: scale(1);
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(95, 129, 168, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 8px rgba(95, 129, 168, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(95, 129, 168, 0);
  }
}
