.markdown h1 {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 20px;
}

.markdown h2 {
  font-size: 18px;
  font-weight: bold;
  margin-top: 25px;
  margin-bottom: 10px;
}

.markdown h3,
.markdown h4,
.markdown h5,
.markdown h6 {
  font-weight: bold;
  margin-top: 15px;
  margin-bottom: 5px;
}

.markdown p {
  margin-bottom: 15px;
  font-size: 16px;
}

.markdown a {
  color: var(--chakra-colors-brand-secondary-main);
  text-decoration: underline;
}

.markdown a:hover {
  text-decoration: underline;
}

.markdown ol,
.markdown ul {
  padding-left: 20px;
  margin-bottom: 20px;
}

.markdown li {
  margin-top: 5px;
  margin-bottom: 7px;
}

.markdown img {
  max-width: calc(100% - 20px);
}

.youtube-embed {
  height: auto;
  width: 100%;
  aspect-ratio: 16 / 9;
}

/* Basic styling for the .markdown table */
.markdown table {
  width: 100%; /* Set table to take full width of its container */
  border-collapse: collapse; /* Collapse borders between cells */
  margin-bottom: 15px;
}

/* Table header styling for .markdown */
.markdown th {
  background-color: #f2f2f2; /* Light gray background for headers */
  padding: 8px; /* Padding inside the header cells */
  border: 1px solid #ddd; /* Light gray border for a subtle effect */
  text-align: left; /* Align text to the left */
}

/* Table cells styling for .markdown */
.markdown td {
  padding: 8px; /* Padding inside the cells */
  border: 1px solid #ddd; /* Light gray border for each cell */
}

/* Styling for rows within .markdown to enhance readability */
.markdown tr:nth-child(even) {
  background-color: #f9f9f9; /* Lighter background for even rows */
}

@media (min-width: 30em) {
  .markdown img {
    max-width: 500px;
  }
}
/**
Wordpress
*/
.wp-block-image img {
  border-color: #999;
  border-style: solid;
  margin-bottom: 10px;
}
