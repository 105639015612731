/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on March 7, 2023 */

@font-face {
  font-family: "fugaz_oneregular";
  src:
    url("fugazone-regular-webfont.woff2") format("woff2"),
    url("fugazone-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
