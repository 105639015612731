#chakra-modal-aside-menu-drawer {
  max-height: 100% !important;
}

.w-md-editor-toolbar {
  button[data-name="comment"],
  button[data-name="code"],
  button[data-name="table"],
  button[data-name="checked-list"],
  button[data-name="hr"],
  button[data-name="fullscreen"],
  button[data-name="codeBlock"],
  button[data-name="ordered-list"],
  .w-md-editor-toolbar ul:nth-child(2) .w-md-editor-toolbar-divider {
    display: none;
  }
}

.partner-landing {
  #service-bar,
  #menu-button-main-navigation,
  #footer-cta,
  #badge-bar,
  #footer,
  #top-nav-desktop a:not(#go-to-app-header-link),
  #newsletter-signup {
    display: none;
  }
}

.partner-hide-chrome {
  #desktop-sidebar {
    display: none;
  }

  #mobile-header,
  #mobile-header-spacer {
    display: none;
  }

  [data-page="app-chat"] #mobile-header-spacer {
    display: block;
    background-color: #111;
  }
}

.blog-bio {
  display: flex;
  flex-direction: column;
  background: #eee;
  padding: 15px;
  align-items: center;
  margin: 25px 0;
  text-align: center;

  p {
    margin-bottom: 0;
  }

  img {
    width: 100px;
    margin-bottom: 10px;
  }
}

.landing-flags {
  background-color: white !important;
}

@media (min-width: 30em) {
  .blog-bio {
    flex-direction: row;
    text-align: left;

    img {
      margin-bottom: 0;
      margin-right: 30px;
    }
  }
}
